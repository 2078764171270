import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash-es/isEmpty';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import { Calendar } from 'react-big-calendar';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import get from 'lodash-es/get';
import { graphql, useStaticQuery } from 'gatsby';

import RichTextWithDictionary from '../RichTextWithDictionary';
import BlockStyles from '../BlockStyles';
import CalendarToolbar from './CalendarToolbar';
import dayjsLocalizer from '../../utils/dayjsLocalizer';
import theme from '../../styles/theme';
import CalendarEventWrapper from './CalendarEventWrapper';

dayjs.extend(localeData);

const StyledCalendarBlock = styled.div`
  margin-top: 0.75rem;

  h5 {
    color: ${prop('theme.colors.black')};
    font-size: 0.875rem;
    font-family: ${prop('theme.fonts.default')};
    font-weight: ${prop('theme.fontWeights.regular')};
    letter-spacing: 0.005rem;
    text-align: left;

    .bold {
      font-weight: ${prop('theme.fontWeights.semiBold')};
    }
  }
`;

const StyledCalendar = styled(Calendar)`
  margin-top: 2rem;
  background-color: ${prop('theme.colors.white')};
  min-height: 23.5rem;
  position: relative;

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    min-height: 30.75rem;
  }

  .rbc-month-view {
    border: 1px solid ${prop('theme.colors.borderGrey')};
    border-top: none;
    border-radius: 0 0 0.5rem 0.5rem;

    /* IE11+ */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      min-height: 28rem;
    }
  }

  .rbc-month-header .rbc-header {
    border-bottom: none;
    border-left: 1px solid ${prop('theme.colors.borderGrey')};
    font-family: ${prop('theme.fonts.default')};
    text-transform: uppercase;
    color: ${prop('theme.colors.black')};
    padding: 0.5rem 0;
    font-size: 0.75rem;
    font-weight: ${prop('theme.fontWeights.medium')};

    &:first-child {
      border-left: none;
    }
  }

  .rbc-day-bg {
    border-left: 1px solid ${prop('theme.colors.borderGrey')};

    /* using hex value one-off to match calendar library styles */
    &:nth-child(1),
    &:nth-child(7n) {
      background-color: ${prop('theme.colors.backgroundGreyLightest')};
    }

    &:nth-child(1) {
      border-left: none;
    }
  }

  .rbc-month-row {
    border-top: 1px solid ${prop('theme.colors.borderGrey')};

    &:last-child {
      border-radius: 0 0 0.5rem 0.5rem;
    }
  }

  .rbc-off-range-bg {
    background-color: ${prop('theme.colors.backgroundGreyLightest')};
  }

  .rbc-today {
    background-color: ${prop('theme.colors.white')};
  }

  .rbc-date-cell {
    @media (max-width: ${prop('theme.breakpoints.sm')}) {
      max-height: 0.9375rem;
      padding-right: 0.1875rem;
    }
  }
`;

const StyleDate = styled.div`
  color: ${(props) =>
    props.$hasEvent
      ? prop('theme.colors.redPrimary')
      : prop('theme.colors.black')};

  font-size: 0.625rem;
  font-weight: ${prop('theme.fontWeights.medium')};
  margin-top: 0.1875rem;

  @media (min-width: ${prop('theme.breakpoints.sm')}) {
    font-size: 1rem;
    margin-top: 0.3125rem;
  }
`;

const propTypes = {
  content: PropTypes.object
};

const defaultProps = {
  content: null
};

function CalendarBlock({ content }) {
  const { calendar_title, calendar_description, calendar_events } = content;
  const {
    document: {
      data: { events, events_2021 }
    }
  } = calendar_events;
  const [isMobile, setIsMobile] = useState(false);
  const staticQueryResult = useStaticQuery(graphql`
    {
      allPrismicEventYear {
        edges {
          node {
            data {
              body {
                ... on PrismicEventYearDataBodyEvent {
                  primary {
                    event_date
                    event_title {
                      richText
                      text
                    }
                    holiday
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const eventsByYear = staticQueryResult
    ? staticQueryResult.allPrismicEventYear.edges
        .flatMap((edge) => get(edge, 'node.data.body', []))
        .map((bodySection) => bodySection.primary)
    : [];

  useEffect(() => {
    let currentMobile = isMobile;
    const resizeMobile = () => {
      const mobileBreak = parseInt(theme.breakpoints.md, 10);
      const vw = window.innerWidth;
      const mobileViewport = vw <= mobileBreak;
      if (mobileViewport !== currentMobile) {
        setIsMobile(mobileViewport);
        currentMobile = mobileViewport;
      }
    };

    if (window && window.innerWidth) {
      resizeMobile();
      window.addEventListener('resize', resizeMobile);
    }
    return () => {
      window.removeEventListener('resize', resizeMobile);
    };
  }, []);

  const allEvents = [...events, ...events_2021, ...eventsByYear];
  const calendarTitle = !isEmpty(calendar_title) && calendar_title.richText[0];

  const calendarEvents = allEvents.map((event, index) => {
    const eventName =
      event.event_title && event.event_title.text ? event.event_title.text : '';
    const eventDates = event.event_date.split('-');
    const eventDate = new Date(eventDates[0], eventDates[1] - 1, eventDates[2]);

    return {
      id: index,
      title: eventName,
      start: eventDate,
      end: eventDate
    };
  });
  const formattedCalendarEvents = calendarEvents.map((event) =>
    dayjs(event.start).format('YYYYMMDD')
  );

  const views = ['month'];
  const localizer = dayjsLocalizer();
  const formats = {
    dateFormat: 'D',
    weekdayFormat: isMobile ? 'ddd' : 'dddd'
  };
  const components = {
    toolbar: CalendarToolbar,
    eventWrapper: CalendarEventWrapper,
    month: {
      dateHeader: (dateObject) => {
        const hasEvent = formattedCalendarEvents.includes(
          dayjs(dateObject.date).format('YYYYMMDD')
        );
        return <StyleDate $hasEvent={hasEvent}>{dateObject.label}</StyleDate>;
      }
    }
  };

  return (
    <BlockStyles>
      <StyledCalendarBlock className='block'>
        {calendarTitle && calendarTitle.type !== 'heading2' && (
          <h2>{calendarTitle}</h2>
        )}
        <RichTextWithDictionary render={calendar_description.richText} />
        <div>
          <StyledCalendar
            localizer={localizer}
            events={calendarEvents}
            views={views}
            components={components}
            startAccessor='start'
            endAccessor='end'
            formats={formats}
          />
        </div>
      </StyledCalendarBlock>
    </BlockStyles>
  );
}

CalendarBlock.propTypes = propTypes;
CalendarBlock.defaultProps = defaultProps;

export default CalendarBlock;
