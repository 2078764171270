import React from 'react';
import Toolbar from 'react-big-calendar/lib/Toolbar';
import styled from 'styled-components';
import { prop } from 'styled-tools';

import leftArrow from '../../images/left-arrow.svg';
import rightArrow from '../../images/right-arrow.svg';

const ToolbarContainer = styled.div`
  &&& {
    padding: 0.625rem 0;
  }

  min-height: 3rem;
  margin: 0;
  background-color: ${prop('theme.colors.backgroundGreyLighter')};
  border-radius: 2.5rem;
  font-weight: ${prop('theme.fontWeights.medium')};
  font-size: 1rem;

  // this is to display the half border on the sides
  ::after {
    content: '';
    position: absolute;
    top: 1.5rem;
    height: 3rem;
    width: 100%;
    display: block;
    border-left: 1px solid ${prop('theme.colors.borderGrey')};
    border-right: 1px solid ${prop('theme.colors.borderGrey')};
  }
`;

const ButtonGroup = styled.div`
  &&& {
    &:first-child {
      margin-left: 1.25rem;
    }

    &:last-child {
      margin-right: 1.25rem;
    }
  }
`;

const Button = styled.button`
  &&& {
    border: none;
    position: relative;
    z-index: 1;
    padding: 0;

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      border: none;
      box-shadow: none;
    }
  }
`;

const ToolbarLabel = styled.div`
  padding: 0;
`;

class CalendarToolbar extends Toolbar {
  render() {
    return (
      <ToolbarContainer className={'rbc-toolbar'}>
        <ButtonGroup>
          <Button onClick={() => this.navigate('PREV')}>
            <img src={leftArrow} />
          </Button>
        </ButtonGroup>
        <ToolbarLabel
          className='rbc-toolbar-label'
          onClick={() => this.navigate('TODAY')}
        >
          {this.props.label}
        </ToolbarLabel>
        <ButtonGroup>
          <Button onClick={() => this.navigate('NEXT')}>
            <img src={rightArrow} />
          </Button>
        </ButtonGroup>
      </ToolbarContainer>
    );
  }
}

export default CalendarToolbar;
