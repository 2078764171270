import React from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';

const EventContent = styled.div`
  color: ${prop('theme.colors.redPrimary')};
  font-weight: ${prop('theme.fontWeights.medium')};
  text-align: left;
  font-size: 0.75rem;
  margin-left: 0.375rem;

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    font-size: 0.5rem;
    margin-left: 0.125rem;
  }

  @media (max-width: ${prop('theme.breakpoints.xs')}) {
    font-size: 0.4735rem;
  }
`;

function CalendarEventWrapper({ event }) {
  return <EventContent title={event.title}>{event.title}</EventContent>;
}

export default CalendarEventWrapper;
